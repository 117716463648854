<template>
  <Marca
    titulo="Reportes de Inventario - Linea Marca"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>
  
<script>
import Marca from "../components/Marca.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioMarcaLayout",
  components: {
    Marca,
  },
  data() {
    return {
      componenteListado: "ListadoInventario",
    };
  },
  methods: {
    servicioGetReporte: ReporteInventarioService.getReporteInventarioMarca,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioMarcaPdf,
  },
};
</script>
  